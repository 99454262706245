import React, { useCallback, useEffect, useState } from 'react';
import { Button, Menu, SettingsOutlinedIcon, Stack, Switch, Typography } from '@esgian/esgianui';
import { FLOW, GENERAL_ACTIONS } from '@store/AppReducer';
import { useStore } from '@store/Store';
import { TextWithTooltipIcon } from '@components/Display';
import debounce from 'lodash.debounce';

function RigsChartSettingButton() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [tempSettings, setTempSettings] = useState({});
  const open = Boolean(anchorEl);

  const {
    state: {
      sectionFilters: {
        rigSupply: { chartSettings }
      }
    },
    dispatch
  } = useStore();

  useEffect(() => {
    // Sync tempSettings with global settings on load or when chartSettings changes
    setTempSettings(chartSettings);
  }, [chartSettings]);

  const handleClick = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const updateGlobalSettings = useCallback(
    debounce((updatedSettings) => {
      dispatch({
        type: GENERAL_ACTIONS.SET_RIG_SUPPLY_CHART_SETTING,
        flow: FLOW.GENERAL,
        payload: { chartSettings: updatedSettings }
      });
    }, 600), // Set debounce delay to 3000ms (3 seconds)
    []
  );

  const handleSwitch = useCallback(
    (key) => {
      const updatedTempSettings = { ...tempSettings, [key]: !tempSettings[key] };
      setTempSettings(updatedTempSettings);

      // Debounce the global state update after the switch is clicked
      updateGlobalSettings(updatedTempSettings);
    },
    [tempSettings, updateGlobalSettings]
  );

  const { dayRate, region, country, contractType, rigType, duration } = tempSettings;

  return (
    <>
      <Button
        variant={'text'}
        id={'chart-settings-button'}
        onClick={handleClick}
        key={'chart-settings'}
        sx={{ color: '#000000' }}
        startIcon={<SettingsOutlinedIcon color={'inherit'} />}>
        Chart Settings
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            minWidth: '15em',
            '&:before': {
              content: '""',
              background: (theme) => theme.palette.background.paper,
              display: 'block',
              position: 'absolute',
              top: 15,
              left: -5,
              width: 10,
              height: 10,
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0
            }
          }
        }}>
        <Stack spacing={1} sx={{ pl: 2, pr: 2, pt: 1, pb: 1 }}>
          <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
            <Typography>Region </Typography>
            <Switch checked={region} onChange={() => handleSwitch('region')} />
          </Stack>
          <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
            <Typography>Country</Typography>
            <Switch checked={country} onChange={() => handleSwitch('country')} />
          </Stack>
          <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
            <TextWithTooltipIcon label={'Rig type'} tooltipText={'Shows for requirements only'} />
            <Switch checked={rigType} onChange={() => handleSwitch('rigType')} />
          </Stack>
          <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
            <Typography>Contract type</Typography>
            <Switch checked={contractType} onChange={() => handleSwitch('contractType')} />
          </Stack>
          <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
            <Typography>Day rate </Typography>
            <Switch checked={dayRate} onChange={() => handleSwitch('dayRate')} />
          </Stack>
          <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
            <Typography>Duration</Typography>
            <Switch checked={duration} onChange={() => handleSwitch('duration')} />
          </Stack>
        </Stack>
      </Menu>
    </>
  );
}

RigsChartSettingButton.propTypes = {};

RigsChartSettingButton.defaultProps = {};

export default RigsChartSettingButton;
